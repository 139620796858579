import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DownloadlinkDialog } from '../../DownloadlinkDialog.component';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  constructor(public supa: SupaService, private helper: HelperService, private modalController: ModalController) { }

  ngOnInit() {
  }

  async downloadpdf(type: string) {
    let furl = (type=="sla") ? this.supa.cngsettings.sla_file : ((type == "toc") ? this.supa.cngsettings.toc_file : this.supa.cngsettings.mes_file)
    const { data, error } = await this.supa.supabase.storage.from('restricted').createSignedUrl(furl, 600)

    if (error != null) {
      this.helper.notify('bestand kon niet worden gedownload!', 'danger');
      console.log(error);
    } else {
      this.helper.downloadLinkModal((data as any).signedURL, furl, 'Klik op de link om het bestand te downloaden. Deze link is 10 minuten geldig.')
    }
  }

}
