import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { HelperService } from '../../../helperservice.service';
import { SupaService } from '../../../supaservice.service';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss'],
})
export class SaleDialog implements OnInit {

  @Input('saleInfo') saleInfo: Sale;

  lijnBenaming = []
  facturatie = []
  contractType = []

  constructor(private modalController : ModalController, private supa: SupaService, private helper: HelperService, private loadingController: LoadingController) { }

  ngOnInit() {
    const includedContractTypes=['Contractnummer']
    this.lijnBenaming = []
    this.facturatie = []
    this.contractType = []
    this.saleInfo.custom_fields.sort((a,b)=>a.position - b.position)
    this.saleInfo.custom_fields.forEach((i, index) => {
      if (i.custom_field_group != undefined) {
        if (i.custom_field_group.name == "Lijnbenaming" && i.value != "" && i.value != undefined) {
          this.lijnBenaming.push(i)
        } else if (i.custom_field_group.name == "Facturatie" && i.value != "" && i.value != undefined) {
          this.facturatie.push(i)
        } else if (i.custom_field_group.name == "Contractbeheer" && i.value != "" && i.value != undefined) {
          if (includedContractTypes.includes(i.name)) {
            if (i.value.substr(0,18) == 'customfieldoption:') {
              const selectedOptionIndex = i.options.findIndex(el => el.value == i.value)
              if (selectedOptionIndex < 0) {
                console.log('Er is een custom field met een onbekende option.', i);
              } else {
                i.value = i.options[selectedOptionIndex].label;
                this.contractType.push(i)
              }
            } else {
              this.contractType.push(i)
            }
          }
        }
      }
    })
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async download(doc: sDoc) {
    const loading = await this.loadingController.create({
      message: 'Download link aanmaken...',
      duration: 0
    });
    loading.present();
    let result = await this.supa.supabase.rpc('get_simplicate_download', {docid: doc.id}).single()
    if (result.error != null) {
      this.helper.notify('Download link kon niet worden aangemaakt', 'danger')
    } else {
      this.helper.downloadLinkModal("data:" + ((result.data as any).type) + ";base64," + (result.data as any).file, doc.title)
    }
    loading.dismiss();
  }

}

export interface Progress {
  id: string;
  color: string;
  label: string;
  position: number;
  chance_to_score: number;
}

export interface Option {
  label: string;
  value: string;
}

export interface CustomFieldGroup {
  id: string;
  name: string;
  position: string;
}

export interface CustomField {
  id: string;
  name: string;
  label: string;
  value: string;
  options: Option[];
  position: number;
  value_type: string;
  render_type: string;
  custom_field_group: CustomFieldGroup;
}

export interface SeparateInvoiceRecipient {
  is_separate_invoice_recipient: boolean;
}

export interface sDoc {
  id: string;
  title: string;
  created_at?: string;
  document_type?: any;
  download_url?: string;
  linked_to?: any;
  updated_at?: string;
}

export interface Sale {
  id: string;
  created: string;
  subject: string;
  modified: string;
  progress: Progress;
  documents: sDoc[];
  created_at: string;
  start_date: string;
  updated_at: string;
  custom_fields: CustomField[];
  chance_to_score: number;
  organization_id: string;
  status_updated_at: string;
  separate_invoice_recipient: SeparateInvoiceRecipient;
}