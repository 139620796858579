import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';
import { SaleDialog } from './sale/sale.component';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {

  constructor(public helper: HelperService, private route: ActivatedRoute, private router: Router, public supa: SupaService, private modalController: ModalController) {
  }

  loadingDone = false
  noError = false
  orgId:Number

  salesInfo:any[] = []

  retry=0

  ngOnInit() {
    this.retry=2
    if (this.supa.profile.is_cng_admin) {
      this.route.queryParams.subscribe(params => {
        if (params['id'] == undefined || Number.isNaN(Number.parseInt(params['id']))) {
          if (this.supa.profile.is_cng_admin) {
            this.router.navigateByUrl('/klanten')
          } else {
            this.router.navigateByUrl('/home')
          }
        } else {
          this.orgId = Number.parseInt(params['id']);
          this.init();
        }
      });
    } else {
      this.orgId = this.supa.profile.organisation_id;
      this.init()
    }
  }

  async onSale(sale) {
    const modal = await this.modalController.create({
      component: SaleDialog,
      cssClass: 'big-modal',
      componentProps: {
        saleInfo: sale
      }
    });
    return await modal.present();
  }

  async init() {
    let { data, error } = await this.supa.supabase.rpc('get_simplicate_sales', { orgid: this.orgId }).single();

    if (error != null) {
      this.noError = false
      console.log(error);
    } else {
      this.salesInfo = data as any[];
      this.noError = true
    }
    
    if (this.noError) {
      this.loadingDone = true;
    } else if (this.retry > 0) {
      this.retry--;
      console.log("Retry...");
      this.init()
    } else {
      this.loadingDone = true;
    }
  }

}
