import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-gebruikers',
  templateUrl: './gebruikers.component.html',
  styleUrls: ['./gebruikers.component.scss'],
})
export class GebruikersComponent implements OnInit {

  constructor(public helper: HelperService, private router: Router, public supa: SupaService, private alertController: AlertController) {
  }

  loadingDone = false
  noError = false
  orgId:Number
  maxUsers=0;

  organisationUsers:any[] = []

  ngOnInit() {
    if (this.supa.profile.is_cng_admin) {
      this.router.navigateByUrl('/klanten')
    } else {
      this.orgId = this.supa.profile.organisation_id;
      this.init()
    }
  }

  async init() {
    let res = await this.supa.supabase.from('organisation').select('maxusers').eq('id', this.orgId).single()
    if (res.error != null) {
      console.log(res.error);
    } else {
      this.maxUsers = res.data.maxusers;
    }

    let { data, error } = await this.supa.supabase.from('profile').select('id,fullname,email,is_login_allowed,is_org_admin,last_sign_in_at').neq('is_cng_admin', true).eq('organisation_id', this.orgId)
    console.log(data, error);

    if (error != null || res.error != null) {
      this.noError = false
    } else {
      this.organisationUsers = data;
      this.noError = true
    }
    
    this.loadingDone = true;
  }

  public async addUser() {
    if (this.organisationUsers.length >= this.maxUsers) {
      this.helper.notify('U heeft het maximale aantal gebruikers al bereikt', 'warning');
      return
    }
    const alert = await this.alertController.create({
      cssClass: 'wider-alert',
      header: 'Gebruiker toevoegen',
      subHeader: 'Voer gegevens in',
      message: 'De nieuwe gebuiker krijgt een email met daarin een link. Daarmee kunnen ze inloggen en vervolgens het wachtwoord beheren en hun naam wijzigen.',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email'
        },
        {
          name: 'naam',
          type: 'text',
          placeholder: 'Volledige naam'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Ok',
          handler: async (d) => {
            this.loadingDone = false;
            let result = await this.supa.supabase.rpc("create_new_user", {
              neworgid: this.orgId, 
              newfullname: d.naam, 
              newemail: d.email
            })

            if (result.error == null) {
                this.helper.notify('Gebruiker aangemaakt', 'success')
                this.init()
            } else {
              console.log(result.error);
              this.helper.notify('Kon gebruiker profiel niet opslaan! Neem contact op met Community Network', 'danger')
              this.loadingDone = true
            }
          }
        }
      ]
    });

    await alert.present();
  }

  public async deleteUser(id, mail) {
    // COunt admins who can login for later
    let admincount = 0
    this.organisationUsers.forEach(u => {
      if (u.is_org_admin && u.is_login_allowed) {
        admincount++;
      }
    })
    const indexOfUser = this.organisationUsers.findIndex(u => u.id == id);
    // at least one admin ..
    if (admincount == 1 && this.organisationUsers[indexOfUser].is_org_admin && this.organisationUsers[indexOfUser].is_login_allowed) {
      this.helper.notify('Er moet tenminste 1 administrator over blijven die ook kan inloggen', 'warning')
      return
    }
    this.helper.promptYesNo('Gebruiker verwijderen', 'Wilt u ' + mail + ' verwijderen?', async ()=>{
      this.loadingDone = false
      let result = await this.supa.supabase.from('profile').delete().eq('id', id)
      if (result.error == null) {
        this.helper.notify('Gebruiker verwijderd', 'success')
        this.init()
      } else {
        console.log(result.error);
        this.helper.notify('Kon de gebruiker niet verwijderen!', 'danger')
        this.loadingDone = true
      }
    }, 'Verwijderen');
  }

  public async resetPwd(mail) {
    this.helper.promptYesNo('Wachtwoord vergeten', 'Wilt u een wachtwoord vergeten mail sturen naar ' + mail + '?', async ()=>{
      let { data, error } = await this.supa.supabase.auth.resetPasswordForEmail(mail)
      if (error != null) {
        this.helper.notify('Kon de email niet versturen', 'danger')
      } else {
        this.helper.notify('De email is verstuurd.', 'success')
      }
    }, 'Versturen');
  }

  public async toggleRights(id) {
    // COunt admins who can login for later
    let admincount = 0
    this.organisationUsers.forEach(u => {
      if (u.is_org_admin && u.is_login_allowed) {
        admincount++;
      }
    })

    const indexOfUser = this.organisationUsers.findIndex(u => u.id == id);
    if (indexOfUser >= 0) {
      this.helper.promptYesNo('Rechten aanpassen', 'Wilt u ' + this.organisationUsers[indexOfUser].email + (this.organisationUsers[indexOfUser].is_org_admin ? ' een normale gebruiker maken' : ' meer rechten geven') + '?', async ()=>{
        // at least one admin ..
        if (admincount == 1 && this.organisationUsers[indexOfUser].is_org_admin && this.organisationUsers[indexOfUser].is_login_allowed) {
          this.helper.notify('Er moet tenminste 1 administrator over blijven die ook kan inloggen', 'warning')
          return
        }
        let { data, error } = await this.supa.supabase.from('profile').update({is_org_admin: !this.organisationUsers[indexOfUser].is_org_admin}).eq('id', this.organisationUsers[indexOfUser].id).single()
        if (error != null) {
          this.helper.notify('Kon de rechten niet aanpassen', 'danger')
        } else {
          this.helper.notify('De rechten zijn aangepast.', 'success')
          this.organisationUsers[indexOfUser].is_org_admin = (data as any).is_org_admin
        }
      });
    }
  }

  public async toggleLogin(id) {
    // COunt admins who can login for later
    let admincount = 0
    this.organisationUsers.forEach(u => {
      if (u.is_org_admin && u.is_login_allowed) {
        admincount++;
      }
    })

    const indexOfUser = this.organisationUsers.findIndex(u => u.id == id);
    if (indexOfUser >= 0) {
      this.helper.promptYesNo('Toestemming voor inloggen', 'Wilt u dat ' + this.organisationUsers[indexOfUser].email + (this.organisationUsers[indexOfUser].is_login_allowed ? ' niet kan inloggen' : ' toegestaan is om in te loggen') + '?', async ()=>{
        // at least one admin ..
        if (admincount == 1 && this.organisationUsers[indexOfUser].is_org_admin && this.organisationUsers[indexOfUser].is_login_allowed) {
          this.helper.notify('Er moet tenminste 1 administrator over blijven die ook kan inloggen', 'warning')
          return
        }
        let { data, error } = await this.supa.supabase.from('profile').update({is_login_allowed: !this.organisationUsers[indexOfUser].is_login_allowed}).eq('id', this.organisationUsers[indexOfUser].id).single()
        if (error != null) {
          this.helper.notify('Kon de rechten niet aanpassen', 'danger')
        } else {
          this.helper.notify('De rechten zijn aangepast.', 'success')
          this.organisationUsers[indexOfUser].is_login_allowed = (data as any).is_login_allowed
        }
      });
    }
  }

}
