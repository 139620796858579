import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-instellingen',
  templateUrl: './instellingen.component.html',
  styleUrls: ['./instellingen.component.scss'],
})
export class InstellingenComponent implements OnInit {

  sla_file = ""
  sla_name = ""
  toc_file = ""
  toc_name = ""
  mes_name = ""
  mes_file = ""
  id =""
  home_page_md = ""

  savingNow = false
  editorHeight = '250px'
  
  constructor(public supa: SupaService, private helper: HelperService) { }

  ngOnInit() {
    this.sla_file = this.supa.cngsettings.sla_file;
    this.sla_name = this.supa.cngsettings.sla_name;
    this.toc_file = this.supa.cngsettings.toc_file;
    this.toc_name = this.supa.cngsettings.toc_name;
    this.mes_name = this.supa.cngsettings.mes_name;
    this.mes_file = this.supa.cngsettings.mes_file;
    this.home_page_md = this.supa.cngsettings.home_page_md;
    this.id = this.supa.cngsettings.id;
  }

  async save() {
    this.savingNow = true;
    let {data, error} = await this.supa.supabase
      .from('cng_settings')
      .update({
        sla_file: this.sla_file,
        sla_name: this.sla_name,
        toc_file: this.toc_file,
        toc_name: this.toc_name,
        mes_file: this.mes_file,
        mes_name: this.mes_name,
        home_page_md: this.home_page_md,
      })
      .eq('id', this.id)
    if (error != null) {
      this.helper.notify('Instellingen konden niet worden opgeslagen', 'danger');
      console.log(error);
    } else {
      this.supa.cngsettings = {
        sla_file: this.sla_file,
        sla_name: this.sla_name,
        toc_file: this.toc_file,
        toc_name: this.toc_name,
        mes_file: this.mes_file,
        mes_name: this.mes_name,
        home_page_md: this.home_page_md,
        id: this.id
      };
      this.helper.notify('Instellingen opgeslagen', 'success');
      this.ngOnInit()
    }
    this.savingNow = false;
  }

  async handleFileInput(event: any, type:string = "toc") {
    let files:FileList = event.target.files;
    if (files.length == 0 || files.length > 1) {
      this.helper.notify("Selecteer 1 bestand", 'warning');
      return;
    }
    if (files.item(0).type != 'application/pdf') {
      this.helper.notify("Alleen pdf is toegestaan", 'warning');
      return;
    }
    let furl = (type=="sla") ? this.supa.cngsettings.sla_file : ((type == "toc") ? this.supa.cngsettings.toc_file : this.supa.cngsettings.mes_file)
    const { data, error } = await this.supa.supabase
      .storage
      .from('restricted')
      .update(furl, files.item(0))
    if (error) {
      this.helper.notify('Er was een probleem tijdens het uploaden', 'danger')
    }
    else {
      this.helper.notify('Uploaden voltooid', 'success')
    }
    console.log(data, error);
  }

}
