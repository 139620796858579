import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';
import { NieuweklantComponent } from './nieuweklant/nieuweklant.component';

@Component({
  selector: 'app-klanten',
  templateUrl: './klanten.component.html',
  styleUrls: ['./klanten.component.scss'],
})
export class KlantenComponent implements OnInit {

  constructor(public supa: SupaService, private modalController: ModalController, private router: Router, private helper: HelperService, private loadingController: LoadingController, private alertController: AlertController) { }

  customers: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  offset = 0
  limit = 10

  ngOnInit() {
    this.supa.supabase.from('organisation').select('id,name,inserted_at,organisation_id_simplicate').order('name').then(v => {
      this.customers.next(v);
    })
  }

  async addCustomer() {
    const modal = await this.modalController.create({
      component: NieuweklantComponent,
      cssClass: 'big-modal',
      componentProps: {
        existingCustomers: this.customers.value.data
      }
    });
    return await modal.present();
  }

  async syncNames() {
    const loading = await this.loadingController.create({
      message: 'Namen aan het synchroniseren.',
      spinner: 'bubbles'
    });
    await loading.present();

    // De namen uit simplicate
    let id_name = []
    let run = true;
    let offset = 0;
    let foundErr = false;
    while (run && !foundErr) {
      let { data, error } = await this.supa.supabase.rpc('get_simplicate_names_f_id', { offset_var: offset }).single();
      loading.message = "Ophalen, " + offset + " van de " + (data as any).metadata.count;
      if (error) {
        console.log(error);
        foundErr = true;
        break;
      } else {
        (data as any).data.forEach(e => {
          id_name.push({id: e.id, name: e.name})
        })
        if ((data as any).metadata.count > id_name.length && (data as any).data.length != 0) {
          offset += 100;
        } else {
          run = false;
        }
      }
    }
    if (foundErr) {
      this.helper.notify('Organisaties konden niet worden opgehaald uit simplicate', 'danger')
      loading.remove();
      return;
    }
    
    // De organisaties uit supabase
    loading.message = id_name.length + " namen gevonden, applicatie organisaties ophalen";
    let { data, error } = await this.supa.supabase.from('organisation').select('organisation_id_simplicate,name,id');
    if (error) {
      console.log(error);
      this.helper.notify('Organisaties konden niet worden opgehaald uit de database', 'danger')
      loading.remove();
      return;
    }

    console.log(id_name, data);

    // Namen updaten
    let message = "";
    for (var i = 0; i < data.length && !foundErr; i++) {
      loading.message = "Updaten van namen, " + (i+1) + " van de " + data.length;
      console.log("Updaten van namen, " + (i+1) + " van de " + data.length);
      let nameindex = id_name.findIndex(e => e.id == data[i].organisation_id_simplicate);
      if (nameindex > -1) {
        if (data[i].name != id_name[nameindex].name) {
          let updateResult = await this.supa.supabase.from('organisation').update({name: id_name[nameindex].name}).eq("id", data[i].id);
          if (updateResult.error) {
            foundErr = true;
            break;
          }
          message += "<strong>" + data[i].name + "</strong> ➝ <strong>" + id_name[nameindex].name + "</strong><br />";
        }
      }
    }
    if (foundErr) {
      console.log(error);
      this.helper.notify('Organisaties konden niet worden geüpdatet', 'danger')
      loading.remove();
      return;
    }
    if (message == "") {
      message = "Er waren geen wijzigingen"
    }

    // toon wijzigingen
    loading.remove();
    const alert = await this.alertController.create({
      header: 'Klaar!',
      cssClass: 'wider-alert',
      message: message,
      // buttons: [
      //   { text: 'OK', role: 'confirm' }
      // ]
    });
    await alert.present();
  }

  onDelete(id, name) {
    this.helper.promptYesNo('Account verwijderen', 'Weet u zeker dat u het account voor ' + name + ' en alle bijbehordende informatie wilt verwijderen? Dit kan niet ongedaan worden gemaakt.', async ()=>{
      this.customers.next(undefined);
      let result = await this.supa.supabase.from('organisation').delete().eq('id', id)
      if (result.error != null) {
        this.helper.notify('Organisatie kon niet worden verwijderd', 'danger')
        console.log(result.error);
      } else {
        this.helper.notify('Organisatie is verwijderd', 'success');
      }
      this.ngOnInit();
    }, 'Verwijderen')
  }

  onRow(id) {
    this.router.navigate(['/bewerkorganisatie'], { queryParams: { id: id }});
  }

}

export interface ListCustomer
{

}