import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  constructor(public supa: SupaService, public toastController: ToastController) { }

  newpass = ""
  newpasscheck = ""

  fullname = this.supa.profile.fullname

  ngOnInit() {
    this.fullname = this.supa.profile.fullname;
  }

  newName() {
    const newname = this.fullname;
    this.fullname = this.supa.profile.fullname;

    if (newname.length < 4) {
      this.notSameToast("Uw naam moet tenminste 4 karakters bevatten")
      return
    }
    this.supa.supabase.from('profile').update({
      fullname: newname
    }).eq('id', this.supa.profile.id).then(v => {
      if (v.error) {
        this.failedToast(v.error.message);
      } else {
        this.updatedNameToast();
        this.fullname = newname;
        this.supa.profile.fullname = newname;
      }
    })
  }

  async notSameToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'warning'
    });
    toast.present();
  }

  async updatedNameToast() {
    const toast = await this.toastController.create({
      message: 'Uw naam is aangepast.',
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

  async updatedToast() {
    const toast = await this.toastController.create({
      message: 'Uw wachtwoord is aangepast.',
      duration: 6000,
      color: 'success'
    });
    toast.present();
  }

  async failedToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: 'danger'
    });
    toast.present();
  }

  newPass() {
    if (this.newpass.length < 8) {
      this.notSameToast("Uw wachtwoord moet tenminste 8 karakters bevatten")
      return
    }
    if (this.newpass != this.newpasscheck) {
      this.notSameToast('De twee wachtwoorden komen niet overeen.');
      return
    }
    this.supa.supabase.auth.updateUser({
      password: this.newpass
    }).then(v => {
      if (v.error) {
        this.failedToast(v.error.message);
      } else {
        this.updatedToast();
        this.newpass = "";
        this.newpasscheck = "";
      }
    }).catch(r => {
      this.failedToast("Wachtwoord niet opgeslagen");
      console.log(r);
    })
  }

  strongPwd() {
    if (this.newpass.length < 6) {
      this.passwordStrength = 0
      return;
    }
    if (this.newpass.length < 8) {
      this.passwordStrength = 0.25
      return;
    }
    
    var alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var passwordArray = this.newpass.split('');
    var totalScore = 0;
    var number = 0;
    var letter = 0;
    var special = 0;

    for(var i = 0; i < passwordArray.length; i++) {
      if (Number.isInteger(Number.parseInt(passwordArray[i]))) {
        number = 1;
      } else if (alphabet.indexOf(passwordArray[i]) > -1) {
        letter = 1;
      } else {
        special = 1;
      }
    }

    var ratingElement = document.querySelector(".rating");
    totalScore = number + letter + special;

    if (totalScore === 1) {
      this.passwordStrength = 0.5
    } else if (totalScore === 2) {
      this.passwordStrength = 0.75
    } else if (totalScore === 3) {
      this.passwordStrength = 1
    }
  }

  passwordStrength = 0
}
