import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-contactformulier',
  templateUrl: './contactformulier.component.html',
  styleUrls: ['./contactformulier.component.scss'],
})
export class ContactformulierComponent implements OnInit {

  fullname=""
  email=""
  organisation=""
  subject=""
  message=""

  finalMsg=""

  constructor(public supa: SupaService, private helper: HelperService, private loadingController: LoadingController) { }

  async getOrgName() {
    if (this.supa.profile.is_cng_admin) {
      return "Community Network Admin"
    }
    let {data, error} = await this.supa.supabase.from('organisation').select('name').eq('id', this.supa.profile.organisation_id).single();
    if (error != null) {
      this.helper.notify('Kon organisatienaam niet laden', 'danger');
      return "";
    }
    return data.name
  }

  ngOnInit() {
    this.init();
  }

  async send() {
    const loading = await this.loadingController.create({
      message: 'Email versturen...',
      duration: 0
    })
    loading.present();

    const body = 
`Organisatie:
${this.organisation}

Naam:
${this.fullname}

Email:
${this.email}

Onderwerp:
${this.subject}

Bericht:
--------
${this.message}`;

    let message = await this.helper.sendMail(this.supa, 'Contactformulier, ' + this.subject, body)
    console.log(message);
    if (message.data === 200) {
      this.helper.notify("Het bericht is verstuurd!", "success", 5000)
      this.finalMsg = body.replace(/<br \/>/gi, '')
    } else {
      this.helper.notify("Bericht kon niet worden verstuurd", "warning")
    }
    loading.dismiss();
  }
  
  async init() {
    this.fullname = this.supa.profile.fullname
    this.email = this.supa.user.email
    this.organisation = await this.getOrgName();
  }

}
