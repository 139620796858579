import { Pipe, PipeTransform } from '@angular/core';
// import * as marked from "marked";
import { marked } from 'marked'

@Pipe({
  name: 'marked'
})
export class MarkedPipe implements PipeTransform {

  async transform(value: any): Promise<string> {
    if (value && value.length > 0) {
      return (await marked(value)).replace(/<a href=\"/g, '<a target="_blank" href=\"');
    }
    return value;
  }

}
