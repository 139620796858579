import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { createClient, SupabaseClient } from '@supabase/supabase-js'
import { BehaviorSubject, timer } from 'rxjs';
import { environment } from '../environments/environment';
import { HelperService } from './helperservice.service';

@Injectable({
  providedIn: 'root'
})
export class SupaService {

  public supabase: SupabaseClient
  public profile: Profile
  public cngsettings: CNGSettings
  public user;

  public AuthStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private helper: HelperService, private alertController: AlertController, private router: Router) {
    router.events.subscribe(o => {
      if(o instanceof NavigationEnd) {
          if (this.AuthStatus.value) {
            if (localStorage.getItem('ltime') != null) {
              localStorage.setItem('ltime', new Date().getTime().toString())
            }
          }
        }
    })
    timer(500,30000).subscribe(r => {
      if (localStorage.getItem('ltime') != null) {
        const ltime = Number.parseInt(localStorage.getItem('ltime'));
        const ntime = new Date().getTime();
        if (ltime + 600000 < ntime) {
          return this.logOut();
        }
        if (ltime + 560000 < ntime) {
          this.helper.promptYesNo('Automatisch uitloggen', 'U wordt over +- 1 minuut automatisch uitgelogd.', ()=>{
            if (localStorage.getItem('ltime') != null) {
              localStorage.setItem('ltime', new Date().getTime().toString())
            }
          }, 'Verleng met 10 minuten')
        }
      }
      console.log(localStorage.getItem('ltime'));
    })
    console.log("INIT S Service");
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
    this.supabase.auth.onAuthStateChange(c => { this.authChecker() });
    this.authChecker();
  }

  private async authChecker() {
    console.log('Auth checker');
    if ((await this.supabase.auth.getUser()).data.user == null) {
      this.profile = null;
      this.user = null;
      this.cngsettings = null;
      this.AuthStatus.next(false);
      return;
    }
    this.user = (await this.supabase.auth.getUser()).data.user
    let response = await this.supabase.from('profile').select('*').eq('id', this.user.id).single();
    if (response.error != null || response.data == null) {
      this.user = null;
      this.profile = null;
      this.cngsettings = null;
      this.AuthStatus.next(false);
      this.helper.notify('Profiel kon niet worden geladen', 'danger');
      console.log('Auth checker error in profile', response.error);
      return;
    }
    if (!response.data.is_login_allowed) {
      this.user = null;
      this.profile = null;
      this.cngsettings = null;
      this.AuthStatus.next(false);
      this.helper.notify('Uw acount is uitgeschakeld, neem contact op met uw organisatie', 'warning');
      this.supabase.auth.signOut()
      return;
    }
    this.profile = response.data as Profile;
    response = await this.supabase.from('cng_settings').select('*').single();
    if (response.error != null) {
      this.user = null;
      this.profile = null;
      this.cngsettings = null;
      this.AuthStatus.next(false);
      console.log('Auth checker error in cng settings', response.error);
      this.helper.notify('Basis gegevens konden niet worden geladen', 'danger');
      return;
    }
    response.data.rnd = (new Date()).getTime() / 1000 | 1000;
    this.cngsettings = response.data as CNGSettings;
    this.AuthStatus.next(true);
  }

  public async forgot(mail) {
    const alert = await this.alertController.create({
      cssClass: 'wider-alert',
      header: 'Wachtwoord vergeten',
      subHeader: 'Voer uw email adres in',
      message: 'U krijgt een email met daarin een code waarmee u eenmalig kunt inloggen. Vervolgens kunt u uw wachtwoord aanpassen. U kunt dit maximaal 1 keer per minuut doen.',
      inputs: [
        {
          name: 'email',
          type: 'email',
          value: mail,
          placeholder: 'Email'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Ok',
          handler: async (d) => {
            if (d.email.length > 5) {
              let { data, error } = await this.supabase.auth.signInWithOtp({email: d.email})
              if (error != null) {
                this.helper.notify('Kon de email niet versturen', 'danger')
              } else {
                this.helper.notify('De email is verstuurd.', 'success')
                this.signInWithOTP(d.email)
              }
            } else {
              return false;
            }
          }
        }
      ]
    });
    
    await alert.present();
  }

  public async signInWithOTP(email_adres:string) {
    const alert = await this.alertController.create({
      cssClass: 'wider-alert',
      header: 'OTP code',
      subHeader: 'Voer de code in',
      message: 'Voer de code in die u heeft ontvangen op \'' + email_adres + '\'.',
      inputs: [
        {
          name: 'code',
          type: 'text',
          value: '',
          placeholder: '1234'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Ok',
          handler: async (d) => {
            if (d.code.length > 3) {
              let { data, error } = await this.supabase.auth.verifyOtp({email: email_adres, token: d.code, type: 'email'})
              if (error != null) {
                this.helper.notify('Kon niet inloggen', 'danger')
              } else {
                this.helper.notify('Ingelogd.', 'success')
              }
            } else {
              return false;
            }
          }
        }
      ]
    });
    
    await alert.present();
  }

  public logIn(email, pwd) {
    localStorage.setItem('ltime', new Date().getTime().toString())
    this.router.navigateByUrl('/home')
    this.supabase.auth.signInWithPassword({ email: email, password: pwd }).then(v => {
      if (v.error != null) {
        this.helper.notify('Inloggen mislukt', 'warning')
      } else {
        this.helper.notify('Inloggen', 'success')
      }
    })
  }

  public logOut() {
    localStorage.removeItem('ltime')
    this.router.navigateByUrl('/home')
    this.supabase.auth.signOut()
  }

}

export interface Profile {
  id:number;
  fullname:string;
  is_cng_admin:boolean;
  is_login_allowed:boolean;
  is_org_admin: boolean;
  organisation_id: number;
}

export interface CNGSettings {
  sla_file: string;
  sla_name: string;
  toc_file: string;
  toc_name: string;
  mes_name: string;
  mes_file: string;
  home_page_md: string;
  id: string;
}