// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fileimg {
  object-fit: contain;
  padding: 6px;
  object-position: center left;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sales/sale/sale.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,4BAAA;AACJ","sourcesContent":[".fileimg {\n    object-fit: contain;\n    padding: 6px;\n    object-position: center left;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
