import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helperservice.service';
import { SupaService } from 'src/app/supaservice.service';

@Component({
  selector: 'app-welcomepass',
  templateUrl: './welcomepass.component.html',
  styleUrls: ['./welcomepass.component.scss'],
})
export class WelcomepassComponent implements OnInit {

  newpass = ""
  newpasscheck = ""

  constructor(private supa:SupaService, private helper: HelperService, private router: Router) { }

  ngOnInit() {}

  newPass() {
    if (this.newpass.length < 8) {
      this.helper.notify("Uw wachtwoord moet tenminste 8 karakters bevatten", 'warning')
      return
    }
    if (this.newpass != this.newpasscheck) {
      this.helper.notify('De twee wachtwoorden komen niet overeen.', 'warning');
      return
    }
    this.supa.supabase.auth.updateUser({
      password: this.newpass
    }).then(v => {
      if (v.error) {
        this.helper.notify(v.error.message, 'danger');
      } else {
        this.helper.notify('Uw wachtwoord is aangepast.', 'success');
        this.newpass = "";
        this.newpasscheck = "";
        this.router.navigateByUrl('/home')
      }
    }).catch(r => {
      this.helper.notify("Wachtwoord niet opgeslagen", 'warning');
    })
  }

  strongPwd() {
    if (this.newpass.length < 6) {
      this.passwordStrength = 0
      return;
    }
    if (this.newpass.length < 8) {
      this.passwordStrength = 0.25
      return;
    }
    
    var alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var passwordArray = this.newpass.split('');
    var totalScore = 0;
    var number = 0;
    var letter = 0;
    var special = 0;

    for(var i = 0; i < passwordArray.length; i++) {
      if (Number.isInteger(Number.parseInt(passwordArray[i]))) {
        number = 1;
      } else if (alphabet.indexOf(passwordArray[i]) > -1) {
        letter = 1;
      } else {
        special = 1;
      }
    }

    var ratingElement = document.querySelector(".rating");
    totalScore = number + letter + special;

    if (totalScore === 1) {
      this.passwordStrength = 0.5
    } else if (totalScore === 2) {
      this.passwordStrength = 0.75
    } else if (totalScore === 3) {
      this.passwordStrength = 1
    }
  }

  passwordStrength = 0
}
