import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-organisationedit',
  templateUrl: './organisationedit.component.html',
  styleUrls: ['./organisationedit.component.scss'],
})
export class OrganisationeditComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, public supa: SupaService, public helper: HelperService, private alertController: AlertController) { }

  loadingDone = false
  orgId:Number

  organisation
  organisationUsers = []

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['id'] == undefined || Number.isNaN(Number.parseInt(params['id']))) {
        this.router.navigateByUrl('/klanten')
      } else {
        this.orgId = Number.parseInt(params['id']);
        this.init();
      }
    });
  }

  async init() {
    console.log("init");
    let { data, error } = await this.supa.supabase.from('organisation').select('*').eq('id', this.orgId).single();

    if (error != null) {
      console.log(error);
      this.helper.notify('Kon de gegevens niet laden', 'danger')
      return
    } else {
      this.organisation = data;
    }

    data = await this.supa.supabase.from('profile').select('id,fullname,email,is_login_allowed,is_org_admin,last_sign_in_at').neq('is_cng_admin', true).eq('organisation_id', this.orgId)
    console.log(data.data);

    this.organisationUsers = data.data
    
    this.loadingDone = true;
  }

  public async addUser() {
    const alert = await this.alertController.create({
      cssClass: 'wider-alert',
      header: 'Gebruiker toevoegen',
      subHeader: 'Voer gegevens in',
      message: 'De nieuwe gebuiker krijgt een email met daarin een link. Daarmee kunnen ze inloggen en vervolgens het wachtwoord beheren en hun naam wijzigen.',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email'
        },
        {
          name: 'naam',
          type: 'text',
          placeholder: 'Volledige naam'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Ok',
          handler: async (d) => {
            this.loadingDone = false;
            let result = await this.supa.supabase.rpc("create_new_user", {
              neworgid: this.orgId, 
              newfullname: d.naam, 
              newemail: d.email
            })

            if (result.error == null) {
                this.helper.notify('Gebruiker aangemaakt', 'success')
                this.init()
            } else {
              console.log(result.error);
              this.helper.notify('Kon gebruiker profiel niet opslaan! Neem contact op met IQMotive', 'danger')
              this.loadingDone = true
            }
          }
        }
      ]
    });

    await alert.present();
  }

  public async deleteUser(id, mail) {
    this.helper.promptYesNo('Gebruiker verwijderen', 'Wilt u ' + mail + ' verwijderen?', async ()=>{
      this.loadingDone = false
      let result = await this.supa.supabase.from('profile').delete().eq('id', id)
      if (result.error == null) {
        this.helper.notify('Gebruiker verwijderd', 'success')
        this.init()
      } else {
        console.log(result.error);
        this.helper.notify('Kon de gebruiker niet verwijderen!', 'danger')
        this.loadingDone = true
      }
    }, 'Verwijderen');
  }

  public async resetPwd(mail) {
    this.helper.promptYesNo('Wachtwoord vergeten', 'Wilt u een wachtwoord vergeten mail sturen naar ' + mail + '?', async ()=>{
      let { data, error } = await this.supa.supabase.auth.resetPasswordForEmail(mail)
      if (error != null) {
        this.helper.notify('Kon de email niet versturen', 'danger')
      } else {
        this.helper.notify('De email is verstuurd.', 'success')
      }
    }, 'Versturen');
  }

  public async toggleRights(id) {
    const indexOfUser = this.organisationUsers.findIndex(u => u.id == id);
    if (indexOfUser >= 0) {
      this.helper.promptYesNo('Rechten aanpassen', 'Wilt u ' + this.organisationUsers[indexOfUser].email + (this.organisationUsers[indexOfUser].is_org_admin ? ' een normale gebruiker maken' : ' meer rechten geven') + '?', async ()=>{
        let { data, error } = await this.supa.supabase.from('profile').update({is_org_admin: !this.organisationUsers[indexOfUser].is_org_admin}).eq('id', this.organisationUsers[indexOfUser].id).single()
        if (error != null) {
          this.helper.notify('Kon de rechten niet aanpassen', 'danger')
        } else {
          this.helper.notify('De rechten zijn aangepast.', 'success')
          this.organisationUsers[indexOfUser].is_org_admin = (data as any).is_org_admin
        }
      });
    }
  }

  public async toggleLogin(id) {
    const indexOfUser = this.organisationUsers.findIndex(u => u.id == id);
    if (indexOfUser >= 0) {
      this.helper.promptYesNo('Toestemming voor inloggen', 'Wilt u dat ' + this.organisationUsers[indexOfUser].email + (this.organisationUsers[indexOfUser].is_login_allowed ? ' niet kan inloggen' : ' toegestaan is om in te loggen') + '?', async ()=>{
        let { data, error } = await this.supa.supabase.from('profile').update({is_login_allowed: !this.organisationUsers[indexOfUser].is_login_allowed}).eq('id', this.organisationUsers[indexOfUser].id).single()
        if (error != null) {
          this.helper.notify('Kon de rechten niet aanpassen', 'danger')
        } else {
          this.helper.notify('De rechten zijn aangepast.', 'success')
          this.organisationUsers[indexOfUser].is_login_allowed = (data as any).is_login_allowed
        }
      });
    }
  }

  openSimp(simplicate_url) {
    window.open(simplicate_url, "_blank");
  }

  public openOrg() {
    this.router.navigate(['/organisatie'], { queryParams: { id: this.orgId }});
  }

  public openSales() {
    this.router.navigate(['/sales'], { queryParams: { id: this.orgId }});
  }

  public async saveOrg() {
    this.loadingDone = false;
    let { data, error } = await this.supa.supabase.from('organisation').update({
        name: this.organisation.name,
        organisation_id_simplicate: this.organisation.organisation_id_simplicate,
        simplicate_url: this.organisation.simplicate_url,
        maxusers: this.organisation.maxusers
      }
    ).eq('id', this.orgId).single();

    if (error != null) {
      console.log(error);
      this.helper.notify('Kon de gegevens niet opslaan', 'danger');
    } else {
      this.helper.notify("Gegevens opgeslagen", 'success');
      this.organisation = data
    }
    this.loadingDone = true
  }

}
