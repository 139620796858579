// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdownfield {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-top: 15px;
  padding: 15px;
  height: 300px;
  overflow-y: scroll;
  border: none;
  width: 100%;
  outline: none;
  transition: height 0.3s linear;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/instellingen/instellingen.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,wCAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EAEA,8BAAA;AAAJ","sourcesContent":[".markdownfield {\n    background-color: white;\n    box-shadow: 0 0 10px rgb(0 0 0 / 15%);\n    margin-top: 15px;\n    padding: 15px;\n    height: 300px;\n    overflow-y: scroll;\n    border: none;\n    width: 100%;\n    outline: none;\n\n    transition: height 0.3s linear;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
