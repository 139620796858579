import { Injectable, NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { ContactformulierComponent } from './pages/contactformulier/contactformulier.component';
import { GebruikersComponent } from './pages/gebruikers/gebruikers.component';
import { HomeComponent } from './pages/home/home.component';
import { InstellingenComponent } from './pages/instellingen/instellingen.component';
import { KlantenComponent } from './pages/klanten/klanten.component';
import { OrganisationComponent } from './pages/organisation/organisation.component';
import { OrganisationeditComponent } from './pages/organisationedit/organisationedit.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SalesComponent } from './pages/sales/sales.component';
import { WelcomepassComponent } from './pages/welcomepass/welcomepass.component';
import { SupaService } from './supaservice.service';

@Injectable()
export class CngAdmin  {
  constructor(public supa: SupaService, public router: Router) {}
  async canActivate(): Promise<boolean> {
    if((await this.supa.supabase.auth.getUser()).data.user == null) {
      this.router.navigate(['home']);
      return false;
    }
    if (!this.supa.AuthStatus.value) {
      this.router.navigate(['home']);
      return false;
    }
    if (!this.supa.profile.is_cng_admin) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'klanten',
    component: KlantenComponent,
    canActivate: [CngAdmin]
  },
  {
    path: 'instellingen',
    component: InstellingenComponent,
    canActivate: [CngAdmin]
  },
  {
    path: 'organisatie',
    component: OrganisationComponent
  },
  {
    path: 'sales',
    component: SalesComponent
  },
  {
    path: 'contactformulier',
    component: ContactformulierComponent
  },
  {
    path: 'bewerkorganisatie',
    component: OrganisationeditComponent,
    canActivate: [CngAdmin]
  },
  {
    path: 'gebruikers',
    component: GebruikersComponent
  },
  {
    path: 'welcome',
    component: WelcomepassComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}