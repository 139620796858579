import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { DownloadlinkDialog } from './DownloadlinkDialog.component';
import { SupaService } from './supaservice.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {


  constructor
  (
    private toastController: ToastController, 
    private alertController: AlertController, 
    private modalController: ModalController, 
    private _location: Location
  ) {
    console.log("INIT H Service");
  }

  public back():void {
    this._location.back()
  }

  async notify(msg, color: PredefinedColors = 'light', duration = 2000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration,
      color: color
    });
    toast.present();
  }
  
  public semiSafePass():string {
    return (Math.random()*10000000).toString(36)
      +(Math.random()*10000000).toString(36)
      +(Math.random()*10000000).toString(36)
      +(Math.random()*10000000).toString(36);
  }

  public async promptYesNo(title:string, header:string, okHandler:Function, okText: string = "Ok", cancelText:string = "Annuleren"): Promise<void> {
    if (localStorage.getItem('ltime') != null) {
      localStorage.setItem('ltime', new Date().getTime().toString())
    }
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: ()=>{ okHandler(); }
        }
      ]
    });

    await alert.present();
  }

  public async downloadLinkModal(url:string, filename:string, text:string = "Klik op de link om het bestand te downloaden") {
    if (localStorage.getItem('ltime') != null) {
      localStorage.setItem('ltime', new Date().getTime().toString())
    }
    const modal = await this.modalController.create({
      cssClass: 'top-modal',
      component: DownloadlinkDialog,
      componentProps: {
        URL: url,
        FILENAME: filename,
        TEXT: text
      }
    });
  
    await modal.present();
  }

  async sendMail(supa:SupaService, subject:string, body: string, to = "jasper.westra@iqmotive.nl", from = "info@3jw.nl") {
    if (localStorage.getItem('ltime') != null) {
      localStorage.setItem('ltime', new Date().getTime().toString())
    }
    return await supa.supabase.rpc('send_mailgun_email', {
      subject: subject,
      from_email: 'info@communitynetwork.nl',
      to_email: 'info@communitynetwork.nl',
      body: body
    });
  }

}

export type PredefinedColors = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';