
import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-downloaddialog',
    styles: [],
    template: `
    <ion-header translucent>
        <ion-toolbar>
            <ion-title>Dynamische link</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="dismissModal()">sluiten</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen>
        <p>{{TEXT}}</p>
        <a target="_blank" [href]="URL | safeUrl" [download]="FILENAME" (click)="dismissModal()">Download: {{FILENAME}}</a>
    </ion-content>
    `
})
export class DownloadlinkDialog {

    @Input('URL') URL = "";
    @Input('FILENAME') FILENAME = "";
    @Input('TEXT') TEXT = "";

    constructor(private modalController: ModalController) {
    }

    dismissModal() {
        this.modalController.dismiss();
    }

}

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
constructor(private domSanitizer: DomSanitizer) {}
transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
}
}
