import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule, CngAdmin } from './app-routing.module';
import { TooltipModule } from 'ng2-tooltip-directive';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { KlantenComponent } from './pages/klanten/klanten.component';
import { NieuweklantComponent } from './pages/klanten/nieuweklant/nieuweklant.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { InstellingenComponent } from './pages/instellingen/instellingen.component';
import { OrganisationComponent } from './pages/organisation/organisation.component';
import { OrganisationeditComponent } from './pages/organisationedit/organisationedit.component';
import { MarkedPipe } from './marked.pipe';
import { SalesComponent } from './pages/sales/sales.component';
import { DownloadlinkDialog, SafeUrlPipe } from './DownloadlinkDialog.component';
import { SaleDialog } from './pages/sales/sale/sale.component';
import { GebruikersComponent } from './pages/gebruikers/gebruikers.component';
import { WelcomepassComponent } from './pages/welcomepass/welcomepass.component';
import { ContactformulierComponent } from './pages/contactformulier/contactformulier.component';

@NgModule({
    declarations: [
        AppComponent,
        MarkedPipe,
        SafeUrlPipe,
        HomeComponent,
        ProfileComponent,
        KlantenComponent,
        NieuweklantComponent,
        InstellingenComponent,
        OrganisationComponent,
        OrganisationeditComponent,
        SalesComponent,
        DownloadlinkDialog,
        SaleDialog,
        GebruikersComponent,
        WelcomepassComponent,
        ContactformulierComponent
    ],
    imports: [CommonModule, BrowserModule, FormsModule, AppRoutingModule, IonicModule.forRoot({
            mode: 'md'
        }), TooltipModule],
    providers: [CngAdmin],
    bootstrap: [AppComponent]
})
export class AppModule {}
