import { Component } from '@angular/core';
import { SupaService } from './supaservice.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  user;
  loggedIn = false

  constructor(public supa: SupaService) {
  }

  chimp() {
    window.open("https://mailchimp.com/", "_blank");
  }
}
