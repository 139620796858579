import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HelperService } from '../../helperservice.service';
import { SupaService } from '../../supaservice.service';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss'],
})
export class OrganisationComponent implements OnInit {

  constructor(public helper: HelperService, private route: ActivatedRoute, private router: Router, public supa: SupaService) { }

  loadingDone = false
  noError = true
  orgId:Number

  organisationInfo

  retry=0

  ngOnInit() {
    this.retry=2
    if (this.supa.profile.is_cng_admin) {
      this.route.queryParams.subscribe(params => {
        if (params['id'] == undefined || Number.isNaN(Number.parseInt(params['id']))) {
          if (this.supa.profile.is_cng_admin) {
            this.router.navigateByUrl('/klanten')
          } else {
            this.router.navigateByUrl('/home')
          }
        } else {
          this.orgId = Number.parseInt(params['id']);
          this.init();
        }
      });
    } else {
      this.orgId = this.supa.profile.organisation_id;
      this.init();
    }
  }

  async init() {
    let { data, error } = await this.supa.supabase.rpc('get_simplicate_organisation', { orgid: this.orgId }).single();

    if (error != null) {
      this.noError = false
      console.log(error);
    } else {
      this.organisationInfo = data;
      this.noError = true
    }
    
    if (this.noError) {
      this.loadingDone = true;
    } else if (this.retry > 0) {
      this.retry--;
      console.log("Retry...");
      this.init()
    } else {
      this.loadingDone = true;
    }
  }

}
