import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { HelperService } from '../../../helperservice.service';
import { SupaService } from '../../../supaservice.service';

@Component({
  selector: 'app-nieuweklant',
  templateUrl: './nieuweklant.component.html',
  styleUrls: ['./nieuweklant.component.scss'],
})
export class NieuweklantComponent implements OnInit {

  @Input() existingCustomers:any[] = [];

  customername = ""
  totalCustomers = 0
  searchCount = 0
  selectedCustomer: Organisation|any = {id:null,exists:false}

  constructor(private modalController : ModalController, private supa: SupaService, public loadingController: LoadingController, private helper: HelperService, private router: Router) { }

  ngOnInit() {
    
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async onSearch() {
    this.selectedCustomer = {id:null,exists:false}
    this.newCustomerSearch.next(undefined);
    let { data, error } = await this.supa.supabase.rpc('get_simplicate_organisations', { query: this.customername });
    const d = data as any;
    this.searchCount = d.metadata.count
    this.totalCustomers = d.metadata.total_count
    let customerDetails: KlantDetails[] = []
    d.data.forEach(k => {
      customerDetails.push({
        id: k.id,
        email: k.email,
        name: k.name,
        url: k.simplicate_url,
        exists: this.CustomerHasAccount(k.id.toString())
      })
    })
    console.log(this.existingCustomers, customerDetails);
    this.newCustomerSearch.next(customerDetails)
  }

  CustomerHasAccount(kid) {
    for(let s of this.existingCustomers) {
      if (kid == s.organisation_id_simplicate) {  
        return true;
      }
    }
    return false
  }

  newCustomerSearch: BehaviorSubject<KlantDetails[]> = new BehaviorSubject<KlantDetails[]>([]);

  public async save() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Aanmaken...',
      duration: 2000
    });
    loading.present();
    this.supa.supabase.from('organisation').insert({
      organisation_id_simplicate : this.selectedCustomer.id,
      simplicate_url: this.selectedCustomer.url,
      name: this.selectedCustomer.name
    }).single().then(d => {
      console.log(d);
      if (d.error != null) {
        console.log(d.error);
        this.helper.notify('Kon niet worden opgeslagen', 'danger');
      } else {
        this.helper.notify('Opgeslagen', 'success');
        this.dismissModal();
        this.router.navigateByUrl('/bewerkorganisatie?id='+(d.data as any).id)
      }
      loading.dismiss();
    })
  }

}

export interface KlantDetails
{
  id : string;
  name : string;
  email : string;
  url : string;
  exists: boolean;
}

export interface Organisation
{
  id?: number;
  inserted_at?: any;
  updated_at?: any;
  organisation_id_simplicate?: string;
  simplicate_url?: string;
  name?: string;
  maxusers?: number;

  exists?: boolean;
}